import React, { Component } from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { graphql } from "gatsby"
import get from "lodash/get"
import ArticleContent from "../components/article/ArticleContent"
import styled from "styled-components"
import colors from "../styles/colors"
import fonts from "../styles/fonts"
import breakpoints from "../styles/breakpoints"
import { Container, Row, Col } from "styled-bootstrap-grid"

const StyledContainer = styled(Container)`
    padding-top: 178px;
    padding-bottom: 90px;
    @media(min-width: ${breakpoints.xl}){
        padding-top: 217px;
        padding-bottom: 106px;
    }
`

const StyledRow = styled(Row)`
    margin: 0 33px;
    @media(min-width: ${breakpoints.lg}){
        margin: 0;
    }
    @media(min-width: ${breakpoints.xl}){
        margin: 0 33px;
    }
    @media(min-width: ${breakpoints.xxl}){
        margin: 0;
    }
`

const PageTitle = styled.h1`
    font-size: 42px;
    color: ${colors.darkBlue};
    letter-spacing: -1.35px;
    line-height: 52px;
    ${fonts.jaggerRegular};
    border-left: 2px solid ${colors.blue};
    padding-left: 18px;
    max-width: 273px;
    margin-bottom: 60px;
    @media(min-width: ${breakpoints.xl}){
        font-size: 62px;
        letter-spacing: -2px;
        line-height: 72px;
        margin-bottom: 0;
    }
`

const Content = styled.div`
    max-width: 576px;
`

class PageTemplate extends Component {
  render() {
    const path = this.props.location.pathname
    console.log(path)
    let page = get(this, "props.data.prismic.page.edges").slice(0, 1).pop()

    console.log(page)
    if (!page) return null

    page = page.node

    return (
      <Layout meta={page._meta}>
        <SEO title={page.title}
             description={page.meta_description}
             path={path}/>
             <StyledContainer>
               <StyledRow>
                 <Col xl={4}>
                   <PageTitle>{page.title}</PageTitle>
                 </Col>
                 <Col xl={8}>
                   <Content>
                      <ArticleContent article={page} />
                   </Content>
                 </Col>
               </StyledRow>
             </StyledContainer>
      </Layout>
    )
  }
}

export default PageTemplate

export const query = graphql`
  query pageQuery($lang: String, $uid: String) {
    prismic {
      page: allText_pages(lang: $lang, uid: $uid) {
        edges {
          node {
            _meta {
              uid
              lang
              alternateLanguages {
                lang
                type
                uid
              }
            }
            title
            meta_description
            body {
              ... on PRISMIC_Text_pageBodyBlock_text {
                type
                primary {
                  content
                }
              }
            }
          }
        }
      }
    }
  }
`

